import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import './Layout.css';
import SpotifyFooter from '../Footer/SpotifyFooter/SpotifyFooter';

const Layout = () => (
  <Suspense fallback={<div className="container-center fallback" />}>
    <Header />
    <main>
      <Outlet />
    </main>
    <Footer />
    <SpotifyFooter />
  </Suspense>
);

export default Layout;
