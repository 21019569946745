import React, { lazy, Suspense } from 'react';
import { useLocation } from 'react-router-dom';

import styles from './SpotyfyFooter.module.css';
import { SPOTIFY_FOOTER_PLAYER } from '../../../constants/constants';

const SpotifyPlayer = lazy(() => import('../../Spotify/SpotifyPlayer'));

const SpotifyFooter = () => {
  const { pathname } = useLocation();
  if (SPOTIFY_FOOTER_PLAYER && pathname !== '/music') {
    return (
      <Suspense fallback={null}>
        <div className={styles.emptySpace} />
        <SpotifyPlayer
          className={styles.spotify}
          title={SPOTIFY_FOOTER_PLAYER.title}
          type={SPOTIFY_FOOTER_PLAYER.type}
          spotifyId={SPOTIFY_FOOTER_PLAYER.id}
          size="compact"
        />
      </Suspense>
    );
  }
  return null;
};

export default SpotifyFooter;
