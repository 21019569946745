import React from 'react';

import { ReactComponent as Facebook } from '../../assests/social_icons/facebook.svg';
import { ReactComponent as Instagram } from '../../assests/social_icons/instagram.svg';
import { ReactComponent as Youtube } from '../../assests/social_icons/youtube.svg';
import { ReactComponent as Spotify } from '../../assests/social_icons/spotify.svg';
import {
  FACEBOOK_PAGE_ID,
  INSTAGRAM_PAGE_ID,
  YOUTUBE_CHANNEL_NAME,
  SPOTIFY_ARTIST_ID,
} from '../../constants/constants';
import styles from './SocialMedias.module.css';

const SocialMedias = () => (
  <div className={styles.socialMedias}>
    <a
      href={`https://www.facebook.com/${FACEBOOK_PAGE_ID}`}
      aria-label="Facebook"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Facebook />
    </a>
    <a
      href={`http://instagram.com/${INSTAGRAM_PAGE_ID}`}
      aria-label="Instagram"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Instagram />
    </a>
    <a
      href={`https://www.youtube.com/c/${YOUTUBE_CHANNEL_NAME}`}
      aria-label="Youtube"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Youtube />
    </a>
    <a
      href={`https://open.spotify.com/artist/${SPOTIFY_ARTIST_ID}`}
      aria-label="Spotify"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Spotify />
    </a>
  </div>
);

export default SocialMedias;
