import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import jamesv from '../../assests/logo/jamesv.svg';
import { ReactComponent as Bars } from '../../assests/icons/bars.svg';
import { ReactComponent as Times } from '../../assests/icons/times.svg';
import { ReactComponent as Envelope } from '../../assests/icons/envelope.svg';
import wave from '../../assests/images/wave.png';
import logo from '../../assests/logo/logo.svg';
import headerImage from '../../assests/images/Somebody Else OUT NOW Banner Soundcloud.png';
import styles from './Header.module.css';
import { EMAIL_ADDRESS, HEADER_TOP } from '../../constants/constants';
import SocialMedias from '../SocialMedias/SocialMedias';

export default function Header() {
  const [showSideDrawer, setShowSideDrawer] = useState(false);

  const sideDrawerOpenHandler = () => {
    setShowSideDrawer(true);
  };

  const sideDrawerCloseHandler = () => {
    setShowSideDrawer(false);
  };

  return (
    <header>
      <div className={styles.headerTop}>
        {HEADER_TOP.externalLink && (
          <a href={HEADER_TOP.externalLink} target="_blank" rel="noopener noreferrer">
            {HEADER_TOP.title}
          </a>
        )}
        {HEADER_TOP.internalLink && <Link to={HEADER_TOP.internalLink}>{HEADER_TOP.title}</Link>}
      </div>
      <div className={styles.header}>
        <Bars className={styles.bars} onClick={sideDrawerOpenHandler} />
        <Link to="/" className={styles.logoLink}>
          <img src={jamesv} alt="JAMES V" className={styles.logo} />
        </Link>
      </div>
      <img src={headerImage} alt="Stream Somebody Else by JAMES V" style={{ width: '100%' }} />
      <a
        className={styles.button}
        style={{ marginTop: '1em' }}
        href="https://link.jamesvmusic.com/somebodyelse"
        target="_blank"
        rel="noopener noreferrer"
      >
        Stream Somebody Else🐬💔🎶
      </a>
      {showSideDrawer && (
        <div className={styles.headerContainer}>
          <div className={styles.headerContent}>
            <div className={styles.container}>
              <Times className={styles.times} onClick={sideDrawerCloseHandler} />
            </div>
            <nav className={styles.container}>
              <img src={wave} alt="separator" />
              <Link to="/videos" className={styles.navLink} onClick={sideDrawerCloseHandler}>
                Videos
              </Link>
              <img src={wave} alt="separator" />
              <Link to="/music" className={styles.navLink} onClick={sideDrawerCloseHandler}>
                Music
              </Link>
              <img src={wave} alt="separator" />
              <Link to="/about" className={styles.navLink} onClick={sideDrawerCloseHandler}>
                About
              </Link>
              <img src={wave} alt="separator" />
              <Link to="/services" className={styles.navLink} onClick={sideDrawerCloseHandler}>
                Services
              </Link>
              <img src={wave} alt="separator" />
            </nav>
            <div className={styles.container}>
              <div className={styles.socialMediasContainer}>
                <SocialMedias />
              </div>
            </div>
            <div className={styles.container}>
              <a className={styles.contact} href={`mailto:${EMAIL_ADDRESS}`}>
                <Envelope />
                Contact me
              </a>
            </div>
            <div className={styles.container}>
              <Link to="/" className={styles.logoLink} onClick={sideDrawerCloseHandler}>
                <img src={logo} alt="logo" className={styles.logoSmall} />
              </Link>
            </div>
          </div>
        </div>
      )}
    </header>
  );
}
