import axios from 'axios';
import React, { lazy, useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Layout from './components/Layout/Layout';
import {
  FORCE_YOUTUBE_MAIN_VIDEO_ID,
  LATEST_VIDEOS_URL,
  YOUTUBE_LATEST_VIDEOS_ID,
  YOUTUBE_MAIN_VIDEO_ID,
} from './constants/constants';

const About = lazy(() => import('./components/About/About'));
const Home = lazy(() => import('./components/Home/Home'));
const NotFound = lazy(() => import('./components/NotFound/NotFound'));
const Video = lazy(() => import('./components/Video/Video'));
const Music = lazy(() => import('./components/Music/Music'));
const BTYRemixContest = lazy(() => import('./components/RemixContest/BTYRemixContest'));
const WIAORemixContest = lazy(() => import('./components/RemixContest/WIAORemixContest'));
const BrokenBeltingChallenge = lazy(() =>
  import('./components/RemixContest/BrokenBeltingChallenge'),
);
const Services = lazy(() => import('./components/Services/Services'));

export default function App() {
  const [latestVideos, setLatestVideos] = useState([]);
  const [mainVideoId, setMainVideoId] = useState('');

  useEffect(() => {
    axios
      .get(LATEST_VIDEOS_URL)
      .then((res) => {
        setLatestVideos(res.data);
        setMainVideoId(
          FORCE_YOUTUBE_MAIN_VIDEO_ID ? YOUTUBE_MAIN_VIDEO_ID : res.data[0].id.videoId,
        );
      })
      .catch(() => {
        setLatestVideos(YOUTUBE_LATEST_VIDEOS_ID);
        setMainVideoId(YOUTUBE_MAIN_VIDEO_ID);
      });
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home videoId={mainVideoId} />} />
        <Route path="about" element={<About />} />
        <Route path="bio" element={<Navigate to="/about" replace />} />
        <Route path="videos" element={<Video latestVideos={latestVideos} />} />
        <Route path="music" element={<Music />} />
        <Route path="back-to-you-remix-competition" element={<BTYRemixContest />} />
        <Route path="when-its-all-over-remix-competition" element={<WIAORemixContest />} />
        <Route path="broken-belting-challenge" element={<BrokenBeltingChallenge />} />
        <Route path="services" element={<Services />} />
        <Route path="voice-lessons" element={<Navigate to="/services" replace />} />

        <Route path="one-on-one-singing-lessons" element={<Navigate to="/services" replace />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}
