import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.module.css';
import jamesv from '../../assests/logo/jamesv.svg';
import { EMAIL_ADDRESS, GENIUS_ID } from '../../constants/constants';
import SocialMedias from '../SocialMedias/SocialMedias';

const Footer = () => (
  <footer>
    <div className={styles.footerTop}>
      <div className={styles.footerContainer}>
        <Link to="/">
          <img src={jamesv} alt="JAMES V" className={styles.logo} />
        </Link>
      </div>
      <div className={styles.footerContainer}>
        <ul className={styles.links}>
          <li>
            <Link to="/videos">Videos</Link>
          </li>
          <li>
            <Link to="/music">Music</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/services">Services</Link>
          </li>
        </ul>
      </div>
      <div className={styles.footerContainer}>
        <div className={styles.mediaTitle}>Extra</div>
        <ul className={styles.links}>
          <li>
            <a href={`https://genius.com/${GENIUS_ID}`} target="_blank" rel="noopener noreferrer">
              Lyrics
            </a>
          </li>
          <li>
            <a
              href="https://soundbetter.com/profiles/461742-james-v"
              target="_blank"
              rel="noopener noreferrer"
            >
              Hire me on SoundBetter
            </a>
          </li>
          <li>
            <a href={`mailto:${EMAIL_ADDRESS}`}>Contact</a>
          </li>
        </ul>
      </div>
      <div className={styles.footerContainer}>
        <div className={styles.mediaTitle}>Social medias</div>
        <div className={styles.socialMediasContainer}>
          <SocialMedias />
        </div>
      </div>
    </div>
    <div className={styles.footerBottom}>
      &copy; JAMES V -&nbsp;
      {new Date().getFullYear()}
    </div>
  </footer>
);

export default Footer;
