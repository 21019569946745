import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import { initializeApp } from 'firebase/app';
import { getAnalytics, setAnalyticsCollectionEnabled } from 'firebase/analytics';
import { getPerformance } from 'firebase/performance';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ScrollToTop from './helpers/ScrollToTop';

const firebaseConfig = {
  apiKey: 'AIzaSyAKVQjCF-WqHprFhgjbwhwQ9nqGxGcLK24',
  authDomain: 'james-v.firebaseapp.com',
  databaseURL: 'https://james-v.firebaseio.com',
  projectId: 'james-v',
  storageBucket: 'james-v.appspot.com',
  messagingSenderId: '531972048065',
  appId: '1:531972048065:web:1fe17aee593743defb8040',
  measurementId: 'G-PMF07B120D',
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

const performance = getPerformance(firebaseApp);
const analytics = getAnalytics(firebaseApp);

// Use emulator and disable analytics for local development
if (process.env.NODE_ENV === 'development') {
  performance.dataCollectionEnabled = false;
  performance.instrumentationEnabled = false;
  setAnalyticsCollectionEnabled(analytics, false);
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <ScrollToTop />
    <App />
  </BrowserRouter>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
