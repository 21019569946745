/* eslint-disable max-len */
export const FACEBOOK_PAGE_ID = 'jamesvofficial';
export const INSTAGRAM_PAGE_ID = 'jamesvmusic';
export const YOUTUBE_CHANNEL_NAME = 'JAMESVmusic';
export const YOUTUBE_CHANNEL_ID = 'UCF-CrVz2gRJvq3vAzxAl_Fg';
export const SPOTIFY_ARTIST_ID = '1Z9wO6vqYpWsd0zWRk06aV';
export const GENIUS_ID = 'jamesvmusic';

export const YOUTUBE_MAIN_VIDEO_ID = 'Bkh_7prZt3E'; // backup video in case of API error
export const FORCE_YOUTUBE_MAIN_VIDEO_ID = true;
export const HIDE_YOUTUBE_MAIN_VIDEO = false;
export const YOUTUBE_LATEST_VIDEOS_ID = [
  { id: { videoId: '9iMi45o9o_E' }, snippet: { publishedAt: '2020-09-14' } },
  { id: { videoId: 'Muqa0K5HcTs' }, snippet: { publishedAt: '2020-09-13' } },
  { id: { videoId: '1_kmwZXJXqs' }, snippet: { publishedAt: '2020-09-10' } },
  { id: { videoId: 'U6JONzZPWl4' }, snippet: { publishedAt: '2020-09-02' } },
  { id: { videoId: 'EH67NM9ICyA' }, snippet: { publishedAt: '2020-06-12' } },
  { id: { videoId: 'nzZm89HFzho' }, snippet: { publishedAt: '2020-04-01' } },
]; // backup video in case of API error
export const YOUTUBE_TRAILER_VIDEO_ID = 'o283V_byeCs';

export const SPOTIFY_MAIN_PLAYER = {
  id: '1UBVBQfNJ9eDvUGTXAyzBX',
  type: 'album',
  title: 'Somebody Else',
  size: 'large', // large or compact
};

// export const SPOTIFY_FOOTER_PLAYER = null;
export const SPOTIFY_FOOTER_PLAYER = {
  id: '1UBVBQfNJ9eDvUGTXAyzBX',
  type: 'album',
  title: 'Somebody Else',
  size: 'compact',
};

export const HEADER_TOP = {
  title: 'Stream Somebody Else🐬💔🎶',
  externalLink: 'https://link.jamesvmusic.com/somebodyelse',
  internalLink: null,
};

export const HOME_HEADER = {
  title: 'Would you like to learn how to sing in one-on-one lessons with me?',
  internalLink: '/one-on-one-singing-lessons',
  externalLink: null,
  linkLabel: 'More info',
  expirationDate: undefined,
  showTimeRemaining: false,
  videoId: null,
  image: null,
};

export const LATEST_VIDEOS_URL =
  'https://us-central1-james-v.cloudfunctions.net/youtube-latestVideos';

export const EMAIL_ADDRESS = 'team@jamesvmusic.com';

export const CALENDAR_LINK = 'https://app.reclaim.ai/m/jamesv/one-on-one-lessons';

export const EMBED_SOCIAL_REF_ID = '9424a1a4edf0788b765a8c62b674d2d13b75dc98';

export const MUSICS = [
  {
    title: 'Somebody Else',
    spotifyTrackId: '1UBVBQfNJ9eDvUGTXAyzBX',
    streamingUrl: 'https://fanlink.tv/jamesv-somebodyelse',
    lyricsUrl: 'https://genius.com/James-v-somebody-else-lyrics',
    description:
      '<h4>STORY BEHIND THE TRACK</h4><p>The story behind Somebody Else covers the phase in a toxic relationship of going through heartbreak and that person hiding the truth from you when you knew the whole time they were cheating on you.</p><h4>THEME</h4><p><strong><i>Somebody Else</i></strong> portrays the emptyness of a broken heart that has been ripped to pieces covered by lies.</p>',
  },
  {
    title: 'Renaissance',
    spotifyTrackId: '3FFzjjCd36xv0xwluLOmWl',
    streamingUrl: 'https://fanlink.tv/jamesv-renaissance',
    description:
      "<h4>STORY BEHIND THE TRACK</h4><p>The story behind RENAISSANCE goes back to my junior year where I had a very rough start of the semester and I went through the worst breakup of my life. This led me to losing myself and in that moment of pure emptyness, in need of starting to do better, I started healing from those experiences because of starting to make music, which led me to find purpose in life. 👇</p><h4>THEME</h4><p><strong><i>RENAISSANCE</i></strong> represents an emotional journey of up and downs I lived and captures real meaningful experiences a lot of you might've lived as well. It represents rebirth after going a through a rough patch healing from those experiences.</p>",
  },
  {
    title: 'Guardian Angel',
    spotifyTrackId: '2nOqNBmJ6EmCZhIFPnXbqt',
    streamingUrl: 'https://fanlink.tv/jamesv-guardianangel',
    lyricsUrl: 'https://genius.com/James-v-guardian-angel-lyrics',
    description:
      "<h4>STORY BEHIND THE TRACK</h4><p>Guardian Angel's story is based on a true love story and is a ballad part of JAMES V's debut EP RENAISSANCE.</p><h4>THEME</h4><p><strong><i>Guardian Angel</i></strong> portrays the most genuine love for someone special in your life or yourself.</p>",
  },
  {
    title: 'Ghost',
    spotifyTrackId: '1AxYSSVg7fLnh0yxjgizWC',
    streamingUrl: 'https://fanlink.tv/jamesv-ghost',
    lyricsUrl: 'https://genius.com/James-v-ghost-lyrics',
  },
  {
    title: 'Only You (JAMES V Remix)',
    spotifyTrackId: '3bAoTd3qhPRoS1dYMrb6lg',
    streamingUrl: 'https://fanlink.tv/onlyyoujamesvremix',
    lyricsUrl: 'https://genius.com/Avello-only-you-james-v-remix-lyrics',
  },
  {
    title: "Don't Let Me Fall",
    spotifyTrackId: '7BUOnau62WVVyzbF1VIwuF',
    streamingUrl: 'https://fanlink.tv/dlmf',
    lyricsUrl: 'https://genius.com/James-v-dont-let-me-fall-lyrics',
    description:
      "<h4>STORY BEHIND THE TRACK</h4><p>Don’t Let Me Fall’s story was inspired by a real experience in Jaime's junior year back in 2018. Jaime met someone by the end of the summer that year who really came into his life at the right moment, since he was going through a rough time with college while living in Madrid. After a few weeks, that love went sour since she started to become colder and more distant, but by that time Jaime had already gotten stuck on her.</p><h4>THEME</h4><p><strong><i>Don't Let Me Fall</i></strong> portrays being stuck on a love that has gone sour and wanting the other person to stick around.</p>",
  },
  {
    title: 'Nurko ft. Chandler Leighton - Disappearing Now (JAMES V Remix)',
    soundCloudTrackId: '1183785676',
    description:
      "<p>This is my remix of <i><strong>'Disappearing Now'</strong></i> by <i>Nurko ft. Chandler Leighton</i></p>",
  },
  {
    title: 'Broken',
    spotifyTrackId: '4WNcR3bUVbGR0kLUZl1AYs',
    streamingUrl: 'https://fanlink.tv/jamesv-broken',
    lyricsUrl: 'https://genius.com/James-v-broken-lyrics',
    description:
      "<h4>STORY BEHIND THE TRACK</h4><p>It had been a few months since I got out of a toxic relationship that ended up in the distance and it was a very difficult time in my life after such a huge loss. So I felt so much resentment that I wrote very relatable lyrics to put words to my feelings and hopefully help someone who's going through the same situation🥺</p><h4>THEME</h4><p><strong><i>Broken</i></strong> portrays feeling lost and desperately resenting yourself because of losing someone very important and seeing there is no way back.</p>",
  },
  {
    title: 'Hope',
    spotifyTrackId: '0PuHPmskVVRzbtrbjWXtj6',
    streamingUrl: 'https://ditto.fm/hope-james-v',
    lyricsUrl: 'https://genius.com/James-v-hope-lyrics',
    description:
      '<h4>THEME</h4><p>What I want to transmit with this track is that if you ever feel lonely and lost somehow given the circumstances, even though life might be mean and hard sometimes, you gotta stay strong and hopeful. Life is a journey that has a bunch of ups and downs. However thankfully, they all happen for a reason and when those are bad times, if we wanna get over them again and again, all we need is some hope forever🙏❤️</p>',
  },
  {
    title: 'Back to You Vs So Far Away',
    spotifyTrackId: '0Q0ybyP3TBCLWD2LzJqAAy',
    streamingUrl: 'https://ditto.fm/back-to-you-vs-so-far-away',
  },
  {
    title: 'Back To You - Upper Mids Remix',
    spotifyTrackId: '2BIMQsNjO8wzRoFZuj37TQ',
    streamingUrl: 'https://ditto.fm/back-to-you-upper-mids-remix',
  },
  {
    title: 'Back To You',
    spotifyTrackId: '6v6PpuUAKEy8WzhrJcXaav',
    streamingUrl: 'https://ditto.fm/back-to-you-james-v-batchbug',
    lyricsUrl: 'https://genius.com/James-v-back-to-you-lyrics',
    description:
      "<h4>THEME</h4><p>This track adresses the me from the past in a nostalgic way to stress the selfishness and lack of purpose I had in life before starting to make music and experience that huge personal change that I'm covering in my EP.</p>",
  },
  {
    title: 'So Far Away',
    spotifyTrackId: '2M69nG3dxAaKMfVVbvDySy',
    streamingUrl: 'https://ditto.fm/so-far-away-james-v',
    lyricsUrl: 'https://genius.com/James-v-so-far-away-lyrics',
  },
];
export const VOCAL_TRIAL_LESSON = {
  title: 'SINGLE VOCAL LESSON',
  description: ['One 50 min vocal lesson'],
  price: 'CA$100',
  link: 'https://www.paypal.com/ncp/payment/35F8L8ZW85GVQ',
  action: 'Book',
};
export const VOCAL_TRAINING_TIERS = [
  {
    id: 'one_on_one_1',
    title: 'VOCAL TRAINING TIER I',
    description: [
      '✨1 weekly lesson per month',
      '✨Weekly custom vocal warmup workouts',
      '✨Free access to all warmup workout videos',
      '✨Personalized growth plan',
      '✨Access to James for feedback',
    ],
    price: 'CA$500',
    link: 'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-2GN65233LH420621MMXR7AKA',
    action: 'Suscribe',
  },
  {
    id: 'one_on_one_2',
    title: 'VOCAL TRAINING TIER II',
    description: [
      '✨2 weekly lessons per month',
      '✨Weekly custom vocal warmup workouts',
      '✨Free access to all warmup workout videos',
      '✨Personalized growth plan',
      '✨Access to James for feedback',
    ],
    price: 'CA$900',
    link: 'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-6A3150001J744671DMXR7IAI',
    action: 'Suscribe',
  },
];
export const SONGWRITING_TRIAL = {
  title: 'SINGLE SONGWRITING LESSON',
  description: ['One 50 min songwriting lesson'],
  price: 'CA$100',
  link: 'https://www.paypal.com/ncp/payment/XGBEX64NEY95W',
  action: 'Book',
};
export const SONGWRITING_TIERS = [
  {
    id: 'one_on_one_3',
    title: '1 WEEKLY SONGWRITING LESSON',
    description: ['✨1 weekly songwriting lesson per month', '✨Access to James for feedback'],
    price: 'CA$500',
    link: 'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-7NP64276U1171643DMXR7K5Y',
    action: 'Suscribe',
  },
  {
    id: 'one_on_one_4',
    title: '2 WEEKLY SONGWRITING LESSONS',
    description: ['✨2 weekly songwriting lessons per month', '✨Access to James for feedback'],
    price: 'CA$900',
    link: 'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-3DM82085872768448MXR7MDI',
    action: 'Suscribe',
  },
];
export const ARTIST_DEVELOPMENT = {
  id: 'artistdev',
  title: 'ARTIST DEVELOPMENT🌟',
  description: [
    '✨Artist Mentoring Calls (Singing, Songwriting, Performance Rehearsals, Vocal Production, Marketing)',
    '✨Weekly custom vocal warmup workouts',
    '✨Free access to all warmup workout videos',
    '✨Personalized growth plan',
    '✨Access to James in the studio',
    '✨Access to James for daily support',
  ],
  price: '',
  link: 'mailto:team@jamesvmusic.com',
  action: 'Inquire',
};
export const PHOTO_GALLERY = [
  {
    original:
      'https://firebasestorage.googleapis.com/v0/b/james-v.appspot.com/o/photos%2FJAMES-V-14.jpg?alt=media',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/james-v.appspot.com/o/photos%2Fthumbnail%2FJAMES-V-14.jpg?alt=media',
    thumbnailClass: 'photoGalleryThumbnail',
  },
  {
    original:
      'https://firebasestorage.googleapis.com/v0/b/james-v.appspot.com/o/photos%2FJAMES-V-10.jpg?alt=media',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/james-v.appspot.com/o/photos%2Fthumbnail%2FJAMES-V-10.jpg?alt=media',
    thumbnailClass: 'photoGalleryThumbnail',
  },
  {
    original:
      'https://firebasestorage.googleapis.com/v0/b/james-v.appspot.com/o/photos%2FJAMES-V-12.jpg?alt=media',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/james-v.appspot.com/o/photos%2Fthumbnail%2FJAMES-V-12.jpg?alt=media',
    thumbnailClass: 'photoGalleryThumbnail',
  },
  {
    original:
      'https://firebasestorage.googleapis.com/v0/b/james-v.appspot.com/o/photos%2FJAMES-V-13.jpg?alt=media',
    thumbnail:
      'https://firebasestorage.googleapis.com/v0/b/james-v.appspot.com/o/photos%2Fthumbnail%2FJAMES-V-13.jpg?alt=media',
    thumbnailClass: 'photoGalleryThumbnail',
  },
];
